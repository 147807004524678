<template>
  <div class="">
    <DashboardNavbar
      :filterSheetName="'filterSheet'"
      :filterSheetStatus="true"
    />
    <PreLoader v-if="isLoading" />

    <div class="app-content pagination-with-content">
      <template v-if="hasEstablishmentFollowUpSettingData">
        <div class="statistics">
          <b-button
            v-b-toggle.EstablishmentFollowUp
            class="btn btn-lg btn-collapse"
          >
            {{ $t("FollowInterests.type") }}
            <i class="fa fa-angle-down" aria-hidden="true"></i>
          </b-button>
          <b-collapse visible id="EstablishmentFollowUp">
            <table class="my-table">
              <tbody>
                <tr>
                  <td>
                    <CustomSelectBox
                      :className="'col-12'"
                      :id="'establishmentFollowUpTypeToken'"
                      :value="establishment.establishmentFollowUpTypeToken"
                      :options="establishmentFollowUpTypeTokenOptions"
                      v-on:changeValue="
                        establishment.establishmentFollowUpTypeToken = $event
                      "
                      :title="$t('FollowInterests.type')"
                      :imgName="'interestFollowUps.svg'"
                    />
                  </td>
                  <td v-if="checkPrivilege(hasFollowUpSettingEdit())">
                    <button
                      style="width: 100%"
                      name="submit"
                      type="submit"
                      class="btn btn-submit"
                      @click.prevent="updateEstablishmentFollowUpType"
                    >
                      {{ $t("edit") }}
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </b-collapse>
        </div>

        <UpdateEstablishmentFollowUpSetting
          :establishment="establishment"
          v-on:refresh="getEstablishmentFollowUpSettingDetails()"
        />
      </template>

      <template v-if="hasData">
        <UserSalesTable
          :usersData="followUpSettings.userSalesFollowUpSettingsData"
          :defaultImg="followUpSettings.userSalesFollowUpSetting.defaultImg"
          :filterData="followUpSettings.filterData"
        />
      </template>
      <ExceptionWithImg
        v-if="exceptionMsg"
        :msg="exceptionMsg"
        :image="exceptionImg"
      />
      <CustomPagination
        v-if="hasData"
        :paginationData="followUpSettings.filterData"
        v-on:changePagination="changePagination($event)"
      />
      <UserFilter
        :theFilterData="followUpSettings.filterData"
        v-on:search="search($event)"
      />
    </div>
  </div>
</template>

<script>
import DashboardNavbar from "./../../../layouts/components/DashboardNavbar.vue";
import PreLoader from "./../../../components/general/PreLoader.vue";
import CustomSelectBox from "./../../../components/general/CustomSelectBox.vue";
import ExceptionWithImg from "./../../../components/general/ExceptionWithImg.vue";
import CustomPagination from "./../../../components/general/CustomPagination.vue";
import UserFilter from "./../../../components/users/UserFilter.vue";
import UpdateEstablishmentFollowUpSetting from "./components/UpdateEstablishmentFollowUpSetting.vue";
import UserSalesTable from "./components/UserSalesTable.vue";
import FollowUpSettings from "./../../../models/followUps/followUpSettings/FollowUpSettings";
import apiFollowUpSetting from "./../../../api/followUps/followUpSettings";
import apiEstablishmentFollowUpSetting from "./../../../api/establishment";
import { STATUS, ESTABLISHMENT_TOKEN } from "./../../../utils/constants";
import generalMixin from "./../../../utils/generalMixin";
import {
  isDataExist,
  checkPrivilege,
  objectToFormData,
} from "./../../../utils/functions";
import { hasFollowUpSettingEdit } from "./../../../utils/privilegeHelper";
import { getDialogOfEstablishmentFollowUpTypes } from "./../../../utils/dialogsOfConstantsLists";

export default {
  name: "UserSales",
  mixins: [generalMixin],
  components: {
    DashboardNavbar,
    PreLoader,
    CustomSelectBox,
    UpdateEstablishmentFollowUpSetting,
    UserSalesTable,
    ExceptionWithImg,
    CustomPagination,
    UserFilter,
  },
  computed: {
    hasEstablishmentFollowUpSettingData() {
      return this.establishment != null;
    },
    hasData() {
      return this.followUpSettings.userSalesFollowUpSettingsData.length != 0;
    },
  },
  data() {
    return {
      followUpSettings: new FollowUpSettings(),
      establishment: null,
      establishmentFollowUpTypeTokenOptions:
        getDialogOfEstablishmentFollowUpTypes(),
    };
  },
  methods: {
    async getEstablishmentFollowUpSettingDetails() {
      this.isLoading = true;
      try {
        this.establishment = null;
        const response = await apiEstablishmentFollowUpSetting.getDetails({
          token: ESTABLISHMENT_TOKEN,
        });
        if (response.data.status == STATUS.SUCCESS) {
          this.establishment = response.data.establishment;
        } else {
          this.showMsg(response.data.msg);
        }
      } catch (error) {
        this.showMsg(error);
      }
      this.isLoading = false;
    },
    async updateEstablishmentFollowUpType() {
      this.isLoading = true;
      try {
        let model = {
          establishmentToken: ESTABLISHMENT_TOKEN,
          establishmentFollowUpTypeToken:
            this.establishment.establishmentFollowUpTypeToken,
        };
        let formData = objectToFormData(model);
        const response = await apiEstablishmentFollowUpSetting.update(formData);
        if (response.data.status == STATUS.SUCCESS) {
          this.showMsg(response.data.msg, true);
          this.$bvModal.hide("UpdateEstablishmentFollowUpSetting");
          this.getEstablishmentFollowUpSettingDetails();
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.showMsg(response.data.msg);
          this.$store.dispatch("logoutUser", response.data.msg);
        } else {
          this.showMsg(response.data.msg);
        }
      } catch (e) {
        this.showMsg(this.$t("errorCatch"));
      }
      this.isLoading = false;
    },

    changePagination(pagination) {
      this.followUpSettings.filterData.fillData(pagination);
      this.getAllUserSales();
    },
    search(data) {
      this.followUpSettings.filterData.fillData(data);
      this.getAllUserSales();
    },
    async getAllUserSales() {
      this.isLoading = true;
      try {
        this.followUpSettings.userSalesFollowUpSettingsData = [];
        const response = await apiFollowUpSetting.getAll(
          this.followUpSettings.filterData
        );
        if (response.data.status == STATUS.SUCCESS) {
          this.exceptionMsg = null;
        } else if (response.data.status == STATUS.NO_CONTENT) {
          this.exceptionMsg = response.data.msg;
          this.exceptionImg = "illustrator-noData.svg";
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.$store.dispatch("logoutUser", response.data.msg);
        } else {
          this.exceptionMsg = response.data.msg;
          this.exceptionImg = null;
        }
        this.followUpSettings.fillData(response.data);
      } catch (e) {
        this.exceptionMsg = this.$t("errorCatch");
        this.exceptionImg = null;
      }
      this.isLoading = false;
    },

    isDataExist,
    checkPrivilege,
    hasFollowUpSettingEdit,
  },
  async created() {
    await this.getEstablishmentFollowUpSettingDetails();
    await this.getAllUserSales();
  },
  watch: {},
};
</script>
