<template>
  <b-modal
    id="UpdateEstablishmentFollowUpSetting"
    scrollable
    centered
    hide-footer
  >
    <template #modal-title>
      <h3>
        <img src="@/assets/images/interestFollowUps.svg" class="icon-lg" />
        {{ $t("FollowInterests.editType") }}
      </h3>
    </template>

    <div class="row">
      <CustomSelectBox
        :className="'col-12'"
        :id="'establishmentFollowUpTypeToken'"
        :value="establishment.establishmentFollowUpTypeToken"
        :options="establishmentFollowUpTypeTokenOptions"
        v-on:changeValue="establishment.establishmentFollowUpTypeToken = $event"
        :title="$t('FollowInterests.type')"
        :imgName="'interestFollowUps.svg'"
      />
    </div>

    <div class="modal-footer">
      <button
        name="submit"
        type="submit"
        class="btn btn-submit"
        @click.prevent="updateEstablishmentFollowUpType"
      >
        {{ $t("ok") }}
      </button>
      <button
        name="submit"
        type="submit"
        class="btn btn-cancel"
        @click.prevent="$bvModal.hide('UpdateEstablishmentFollowUpSetting')"
      >
        {{ $t("cancel") }}
      </button>
    </div>
  </b-modal>
</template>

<script>
import { getDialogOfEstablishmentFollowUpTypes } from "./../../../../utils/dialogsOfConstantsLists";
import CustomSelectBox from "./../../../../components/general/CustomSelectBox.vue";
import { STATUS, ESTABLISHMENT_TOKEN } from "./../../../../utils/constants";
import { objectToFormData } from "./../../../../utils/functions";
import apiEstablishmentFollowUpSetting from "./../../../../api/establishment";
import generalMixin from "./../../../../utils/generalMixin";

export default {
  mixins: [generalMixin],
  components: {
    CustomSelectBox,
  },
  data() {
    return {
      establishmentFollowUpTypeTokenOptions:
        getDialogOfEstablishmentFollowUpTypes(),
    };
  },
  props: ["establishment"],
  computed: {},
  methods: {
    async updateEstablishmentFollowUpType() {
      this.isLoading = true;
      try {
        let model = {
          establishmentToken: ESTABLISHMENT_TOKEN,
          establishmentFollowUpTypeToken:
            this.establishment.establishmentFollowUpTypeToken,
        };
        let formData = objectToFormData(model);
        const response = await apiEstablishmentFollowUpSetting.update(formData);
        if (response.data.status == STATUS.SUCCESS) {
          this.showMsg(response.data.msg, true);
          this.$bvModal.hide("UpdateEstablishmentFollowUpSetting");
          this.$emit("refresh");
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.showMsg(response.data.msg);
          this.$store.dispatch("logoutUser", response.data.msg);
        } else {
          this.showMsg(response.data.msg);
        }
      } catch (e) {
        this.showMsg(this.$t("errorCatch"));
      }
      this.isLoading = false;
    },
  },
  async created() {},
};
</script>
